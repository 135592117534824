// https://api.jqueryui.com/autocomplete/
import 'jquery-ui-bundle'
import { completeValues } from './form/employer'

export const isWidgetLoaded = ($input) => !!$input.autocomplete('instance')

export const initWidget = ($input, data) => {
    if (isWidgetLoaded($input)) {
        return
    }

    $input
        .autocomplete({
            delay: 0,
            minLength: 2,
            source: [],
        })
        .on('focus', () => {
            $input.autocomplete('search', $input.val())
        })
        .on('autocompleteselect', (event, ui) => {
            completeValues(event, data, ui.item.value)
        })
        .on('autocompleteresponse', (event, ui) => {
            ui.content.length === 1 && completeValues(event, data, ui.content[0]?.value)
        })

    $input.autocomplete('search', $input.val())
}

export const openWidget = ($input) => {
    isWidgetLoaded($input) && $input.focus()
}

export const closeWidget = ($input) => {
    isWidgetLoaded($input) && $input.autocomplete('close')
}

export const setWidgetData = ($input, data) => {
    isWidgetLoaded($input) && $input.autocomplete('option', 'source', data)
}
