import $ from 'jquery'
import _ from 'lodash'

import { $disableElement, $elementExists, $hideElement, addFlashMessageError, addFlashMessageInfo, addFlashMessageSuccess } from './shared'
import { $feedbackSpinnerFinnish, $feedbackSpinnerStart, ATTR } from './validation/helpers'
import { processPromoSaleChange } from './form/promoCode'
import { selectors } from './selectors'
import { handleDataResponseEmployer, handleDataResponseSelfEmployer } from './form/employer'
import { handlePinCodeResendResponse } from './form/pincode'

let abortController = null

const abortSignal = () => {
    abortController?.abort()
    abortController = new AbortController()
}

// -----------------------------------------------

export const promoCodeRequest = async ($input, $submit) => {
    const promoCode = _.trim($input.val())

    abortSignal()
    $hideElement($submit)
    $feedbackSpinnerStart($input)

    await fetch(promoCodeVerificationUrl, {
        method: 'POST',
        body: JSON.stringify({ promoCode }),
        signal: abortController.signal,
    })
        .then((response) => response.json())
        .then(({ isValid, promoSale }) => {
            processPromoSaleChange($input, promoSale, $submit)
            isValid ? $input._forceSuccess(ATTR.VALID._EXTERNAL, `Vaše sleva: ${promoSale}%`) : $input._forceError(ATTR.VALID._EXTERNAL, 'Neplatný kód')
        })
        .catch((e) => console.warn(e) || $input._forceError(ATTR.VALID._EXTERNAL, 'Server error.'))

    $feedbackSpinnerFinnish($input)
}

export const callMeBackRequest = () => {
    const MESSAGES = {
        SUCCESS: 'Děkujeme. Ozveme se Vám!',
        ERROR: 'Něco se pokazilo, prosím kontaktujte naše centrum na telefonním čísle +420 226 219 946.',
    }

    const $input = $(selectors.inputs.homePageCallMeBack)
    const $alertBox = $(selectors.errors.alertBox)
    const phone = _.trim($input.val().replace(/\s+/g, ''))

    abortSignal()

    fetch(callMeBackUrl, {
        method: 'POST',
        body: JSON.stringify({ phone }),
        signal: abortController.signal,
    })
        .then((response) => response.json())
        .then(({ accepted }) => {
            accepted && $elementExists($alertBox) && addFlashMessageInfo(MESSAGES.SUCCESS, $alertBox.find('div.cell'))
            !accepted && $elementExists($alertBox) && addFlashMessageError(MESSAGES.ERROR, $alertBox.find('div.cell'))
        })
        .catch((e) => console.warn(e))
}

export const resendPinCodeRequest = () => {
    abortSignal()

    fetch(resendPinUrl, {
        method: 'POST',
        body: JSON.stringify({ activationCode }),
        signal: abortController.signal,
    })
        .then((response) => response.json())
        .then((data) => {
            handlePinCodeResendResponse(data)
        })
        .catch((e) => console.warn(e))
}

export const newsletterRequest = async () => {
    const MESSAGES = {
        SUCCESS: 'Váš email byl úspěšně přidán k odběru newsletteru.',
        ERROR: 'Bohužel se Váš email nepodařilo přidat k odběru newsletteru.',
    }

    const $input = $(selectors.inputs.newsletter)
    const $submit = $(selectors.submits.newsletter)

    const email = _.trim($input.val())
    const url = $input.data('url') // TODO make normally !!!

    abortSignal()
    $feedbackSpinnerStart($input)

    await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ email }),
    })
        .then((response) => response.json())
        .then(({ accepted }) => {
            accepted && addFlashMessageSuccess(MESSAGES.SUCCESS, $(selectors.content.newsletterAlert))
            !accepted && addFlashMessageError(MESSAGES.ERROR, $(selectors.content.newsletterAlert))

            $disableElement($submit)
        })
        .catch((e) => console.warn(e))

    $feedbackSpinnerFinnish($input)
}

export const helpMeRequest = async () => {
    const MESSAGES = {
        SUCCESS: 'Děkujeme. Ozveme se Vám!',
        ERROR: 'Něco se pokazilo, prosím kontaktujte naše centrum na telefonním čísle +420 226 219 946.',
    }

    const $input = $(selectors.inputs.phoneNumberHelpMe)
    const $submit = $(selectors.submits.helpMe)

    const phone = _.trim($input.val().replace(/\s+/g, ''))
    const url = $input.data('url') // TODO make normally !!!

    abortSignal()
    $feedbackSpinnerStart($input)

    await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ phone }),
    })
        .then((response) => response.json())
        .then(({ accepted }) => {
            accepted && addFlashMessageSuccess(MESSAGES.SUCCESS, $(selectors.content.helpMeAlert))
            !accepted && addFlashMessageError(MESSAGES.ERROR, $(selectors.content.helpMeAlert))

            $disableElement($submit)
        })
        .catch((e) => console.warn(e))

    $feedbackSpinnerFinnish($input)
}

export const actualizeBankAccountRequest = async () => {
    const $input = $(selectors.inputs.banks)
    const bankId = $input.val()

    thePayConfig.bankId = bankId // Defined in [Twig]

    const $accountInfoBlock = $(selectors.inputs.banksActualizeAccountInfo)
    const $accountInfoAccountNumber = $accountInfoBlock.find('input[name="accountNumber"]')

    const $thePayBlock = $(selectors.content.thePay)
    const $qrPayBlock = $(selectors.content.qrPayment)
    const $bankRedirect = $(selectors.inputs.banksRedirectUrl)

    // ---------------------------------------

    const reset = () => {
        $accountInfoBlock.hide()
        $accountInfoAccountNumber.val(process.env.COMPANY_BANK_ACCOUNT)
        $thePayBlock.hide()
        $qrPayBlock.hide()
    }

    const processActualization = (thePayRender, bankPayment, qrCode) => {
        const bank = bankPayment.bank

        if (bank.accountNumber !== null) {
            $accountInfoAccountNumber.val(bank.accountNumber + '/' + bank.code)
        }

        if (qrCode) {
            $qrPayBlock.find('img').attr('src', qrCode)
            $qrPayBlock.show()
        }

        if (bank.url !== null) {
            $bankRedirect.attr('href', bank.url)
            $bankRedirect.show()
        }

        if (bankPayment.type === 'thePay') {
            $(selectors.content.thePaySelect).attr('id', 'thepay-select-' + bankPayment.ext)
            $(selectors.content.logoImage).html(thePayRender)
            $thePayBlock.show()
        }

        $accountInfoBlock.show()
    }

    // ---------------------------------------

    abortSignal()
    $feedbackSpinnerStart($input.parents('label').find('span.select2 > span.selection')) // Specific for Select2
    reset()

    await fetch(bankAccountActualizeUrl, {
        method: 'POST',
        body: JSON.stringify({ bankId }),
        signal: abortController.signal,
    })
        .then((response) => response.json())
        .then(({ thePayRender, bankPayment, qrCode }) => processActualization(thePayRender, JSON.parse(bankPayment), qrCode))
        .catch((e) => console.warn(e))

    $feedbackSpinnerFinnish($input)
}

export const employerRequest = async ($input) => {
    const name = _.trim($input.val())

    abortSignal()
    $feedbackSpinnerStart($input)

    await fetch(legalEntityVerificationNameUrl, {
        method: 'POST',
        body: JSON.stringify({ name }),
        signal: abortController.signal,
    })
        .then((response) => response.json())
        .then((data) => _.map(JSON.parse(data.data), (row) => ({ name: row.name, city: row.city, ico: row.ico })))
        .then((data) => handleDataResponseEmployer(data))
        .catch((e) => console.warn(e))
}

export const selfEmployerRequest = async ($input) => {
    const ico = _.trim($input.val())

    abortSignal()
    $feedbackSpinnerStart($input)

    await fetch(legalEntityVerificationIcoUrl, {
        method: 'POST',
        body: JSON.stringify({ ico }),
        signal: abortController.signal,
    })
        .then((response) => response.json())
        .then((data) => _.map(JSON.parse(data.data), (row) => ({ name: row.name, city: row.city, ico: row.ico, sphere: row.businessField.value })))
        .then((data) => handleDataResponseSelfEmployer(data))
        .catch((e) => console.warn(e))
}
