'use strict'

import $ from 'jquery'
import _ from 'lodash'

import { $checkboxIsChecked, $elementHashVal, $elementHasVal, $elementIsVisible, $enableElement, $radioIsChecked, $readonlyElement, $selectIsSelected } from '../shared'
import { rule, flag } from './enums'

export const DEBUG = false // Debug Validator events

export const ICON_CLASS = {
    _BASE: 'i.fa',
    _SPINNER: 'fa fa-spinner fa-spin',
    _SPINNER_DEL: 'i.fa-spinner',
    _CHECK: 'fa fa-check',
    _TIMES: 'fa fa-times',
}

export const SELECTOR = {
    _INPUT_GROUP: '.form-group',
    _INPUT_FEEDBACK_ERROR: 'span.form-error',
    _INPUT_FEEDBACK_SUCCESS: 'span.form-success',
}

export const CLASS = {
    _SUCCESS: 'valid',
    _ERROR: 'invalid',
}

export const ATTR = {
    ERROR: {
        _CLIENT: 'data-error-client',
    },
    VALID: {
        _CLIENT: 'data-valid-client',
        _EXTERNAL: 'data-valid-external',
        _CUSTOM: 'data-valid-custom',
    },
    STATE: {
        _INIT: '😑',
        _SUCCESS: '😍',
        _ERROR: '😡',
    },
}

export const hasMask = (rules) => rule.mask in rules

export const hasRegex = (rules) => rule.regex in rules

export const hasSelected = (rules) => rule.selected in rules

export const hasRanged = (rules) => rule.ranged in rules

export const hasCustom = (rules) => rule.custom in rules

export const hasExternal = (rules) => rule.external in rules

export const hasClientRule = (rules) => hasMask(rules) || hasRegex(rules) || hasSelected(rules)

export const hasCustomRule = (rules) => hasCustom(rules)

export const hasExternalRule = (rules) => hasExternal(rules)

export const hasDetachedFlag = (rules) => flag.detached in rules

export const isHTMLValid = (selector) => $(selector).get(0).checkValidity()

const hasErrorClient = ($element) => $element._hasAttr(ATTR.VALID._CLIENT) && $element.attr(ATTR.VALID._CLIENT) === ATTR.STATE._ERROR
const hasErrorExternal = ($element) => $element._hasAttr(ATTR.VALID._EXTERNAL) && $element.attr(ATTR.VALID._EXTERNAL) === ATTR.STATE._ERROR
const hasErrorCustom = ($element) => $element._hasAttr(ATTR.VALID._CUSTOM) && $element.attr(ATTR.VALID._CUSTOM) === ATTR.STATE._ERROR
export const hasError = ($element) => hasErrorClient($element) || hasErrorExternal($element) || hasErrorCustom($element)

export const hasSuccessClient = ($element) => !$element._hasAttr(ATTR.VALID._CLIENT) || $element.attr(ATTR.VALID._CLIENT) === ATTR.STATE._SUCCESS
const hasSuccessExternal = ($element) => !$element._hasAttr(ATTR.VALID._EXTERNAL) || $element.attr(ATTR.VALID._EXTERNAL) === ATTR.STATE._SUCCESS
const hasSuccessCustom = ($element) => !$element._hasAttr(ATTR.VALID._CUSTOM) || $element.attr(ATTR.VALID._CUSTOM) === ATTR.STATE._SUCCESS
export const hasSuccess = ($element) => hasSuccessClient($element) && hasSuccessExternal($element) && hasSuccessCustom($element)

// ------- Validators -------

export const validateSelect = ({ selector }) => $selectIsSelected($(selector))

export const validateRadio = ({ selector }) => $radioIsChecked($(selector))

export const validateCheckBox = ({ selector }) => $checkboxIsChecked($(selector))

export const validateRange = () => true // TODO make $element.val() >= min && $element.val() <= max

export const validateInput = ({ selector, rules }) => {
    const $input = $(selector)
    const value = _.trim($input.val())

    return !!value.match(rules.regex)
}

export const validateDate = ({ selector, rules }) => {
    const $date = $(selector)
    const value = _.trim($date.val())

    return !!value.match(rules.regex)
}

// ------- Styling -------

export const $feedbackCrossAdd = ($input) => $input.after(`<i class="${ICON_CLASS._TIMES}" title="Odstranit"></i>`)

export const $feedbackCrossRemove = ($input) => $input.parent().find(ICON_CLASS._BASE).remove()

export const $buttonSpinnerStart = ($button) => $buttonSpinnerFinnish($button) && $button.prepend(`<i class="${ICON_CLASS._SPINNER} mr-3"></i>`)

export const $buttonSpinnerFinnish = ($button) => $button.parent().find(ICON_CLASS._BASE).remove()

export const $feedbackSpinnerStart = ($input) => $feedbackSpinnerFinnish($input) && $input.after(`<i class="${ICON_CLASS._SPINNER}"></i>`)

export const $feedbackSpinnerFinnish = ($input) => $input.parent().find(ICON_CLASS._SPINNER_DEL).remove()

export const $resetFeedbacks = ($input) => {
    $input._removeClasses([CLASS._ERROR, CLASS._SUCCESS])
    _.forEach($getFeedbacks($input), ($feedback) => $feedback._removeClasses(['is-visible']))
}

const $getFeedbacks = ($input) => [$feedbackError($input), $feedbackSuccess($input)]

const $feedbackError = ($input) => $input.parents(SELECTOR._INPUT_GROUP).find(SELECTOR._INPUT_FEEDBACK_ERROR)

const $feedbackSuccess = ($input) => $input.parents(SELECTOR._INPUT_GROUP).find(SELECTOR._INPUT_FEEDBACK_SUCCESS)

const displayError = ($input, $feedback) => {
    $resetFeedbacks($input)

    // Handles empty PromoCode inputs (if deleted|focused|clicked into)
    if ($input._isEmpty() && $input._isDetached()) return

    $input.addClass(CLASS._ERROR)
    $feedback.addClass('is-visible')
}

const displaySuccess = ($input, $feedback) => {
    $resetFeedbacks($input)

    // Handles empty PromoCode inputs (if deleted|focused|clicked into)
    if ($input._isEmpty() && $input._isDetached()) return

    $input.addClass(CLASS._SUCCESS)
    $feedback.addClass('is-visible')
}

const attrError = ($input, attr) => $input.attr(attr, ATTR.STATE._ERROR)

const attrSuccess = ($input, attr) => $input.attr(attr, ATTR.STATE._SUCCESS)

const feedbackError = ($input, message = $input.attr(ATTR.ERROR._CLIENT)) => _.isString(message) && $feedbackError($input).html(message)

const feedbackSuccess = ($input, message) => _.isString(message) && $feedbackSuccess($input).html(message)

const handleValidationSuccess = ($input, attr, result) => {
    result && attrSuccess($input, attr) && feedbackSuccess($input, result)
}

const handleValidationError = ($input, attr, result) => {
    attrError($input, attr) && feedbackError($input, result)
}

// ------- API response Caching -------

export const fetchCache = ($input) => {
    const store = $input.data('_API_store_') ?? {}
    return _.has(store, $input.val()) ? Promise.resolve(store[$input.val()]) : Promise.reject(null)
}

export const setCache = ($input, data) => {
    $input._dataExtend('_API_store_', data)
    return data
}

// ------- jQuery Extensions -------

export const init$fnExtensions = () => {
    // ---------- Basic ----------
    $.fn._rotate = function (deg) {
        $(this).css({ transform: `rotate(${deg}deg)` })
    }
    $.fn._hasAttr = function (name) {
        return this.attr(name) !== undefined
    }
    $.fn._removeClasses = function (classes) {
        this.removeClass(classes.join(' '))
    }
    $.fn._dataExtend = function (key, data) {
        const store = this.data(key) ?? {}
        this.data(key, { ...store, [this.val()]: data })
    }
    $.fn._isEmpty = function () {
        return !$elementHasVal(this)
    }
    $.fn._isVisible = function () {
        return $elementIsVisible(this)
    }
    // ---------- Validations ----------
    $.fn._isValid = function () {
        return hasSuccess(this)
    }
    $.fn._isInValid = function () {
        return hasError(this)
    }
    $.fn._addError = function () {
        hasError(this) && displayError(this, $feedbackError(this))
    }
    $.fn._addSuccess = function () {
        hasSuccess(this) && displaySuccess(this, $feedbackSuccess(this))
    }
    $.fn._addFeedback = function () {
        this._addError() || this._addSuccess()
    }
    $.fn._forceError = function (attr, result) {
        handleValidationError(this, attr, result) || this._addFeedback()
    }
    $.fn._forceSuccess = function (attr, result) {
        handleValidationSuccess(this, attr, result) || this._addFeedback()
    }
}

const init$SubmitExtensions = ($submit) => {
    $submit._$inputs = []
    $submit._addInput = ($input) => $submit._$inputs.push($input)
    $submit._submit = () => $submit.parents('form').submit()
}

const init$inputExtensions = ($input) => {
    $input._$submit = null
    $input._addSubmit = ($submit) => ($input._$submit = $submit)
    $input._notify = () => $input._$submit?._checkState()
    // $input._addError = () => hasError($input) && displayError($input, $feedbackError($input))
    // $input._addSuccess = () => hasSuccess($input) && displaySuccess($input, $feedbackSuccess($input))
    // $input._addFeedback = () => $input._addError() || $input._addSuccess()
    $input._onValidationError = (attr, result) => handleValidationError($input, attr, result)
    $input._onValidationSuccess = (attr, result) => handleValidationSuccess($input, attr, result)
    // $input._isVisible = () => $elementIsVisible($input)
    // $input._isValid = () => hasSuccess($input)
    $input._update = () => $input.data('prevState', $elementHashVal($input))
    $input._changed = () => $input.data('prevState') !== $elementHashVal($input)
}

export const construct$Submit = (submit) => {
    const { selector, callbacks, events } = submit
    const $submit = $(selector)

    init$SubmitExtensions($submit)

    $submit._checkState = () => {
        const $inputs = _.filter($submit._$inputs, ($input) => $input._isVisible())
        const formValid = _.reduce($inputs, (result, $input) => result && $input._isValid(), true)

        formValid ? $enableElement($submit) : $readonlyElement($submit) // <Input> may Enable or Disable <Submit>
        // formValid && $enableElement($submit) // <Input> validation may only Enable <Submit> TODO ??

        return formValid
    }

    $submit._validateForm = async () => {
        const $inputs = _.filter($submit._$inputs, ($input) => $input._isVisible())

        return await Promise.all(_.map($inputs, ($input) => $input._validate())).then(() => {
            const formValid = $submit._checkState()
            formValid ? $enableElement($submit) : $readonlyElement($submit) // <Submit> click may Enable or Disable itself

            return formValid
        })
    }

    $submit.on(events, async (e) => {
        e.preventDefault()
        const { onError, onSuccess, onValidate, beforeSubmit } = callbacks
        const formValid = await $submit._validateForm()

        onValidate?.()
        beforeSubmit?.()
        !formValid && onError?.()
        formValid && (onSuccess ?? $submit._submit)()
    })

    $readonlyElement($submit) // <Submit> Disabled by default

    return $submit
}

export const construct$Validator = (input) => {
    const { selector, events, errorEvents, rules, processClient, processExternal, processCustom } = input
    const $input = $(selector)

    init$inputExtensions($input)

    $input._isDetached = () => hasDetachedFlag(rules)

    $input._validate = async () => {
        if (!$input._changed()) return
        $input._update()

        await processClient()
            .then(async (success) => {
                $input._onValidationSuccess(ATTR.VALID._CLIENT, success)
                DEBUG && console.log('processClient then: ' + success)

                await processCustom()
                    .then(async (success) => {
                        $input._onValidationSuccess(ATTR.VALID._CUSTOM, success)
                        DEBUG && console.log('processCustom then: ' + success)

                        await processExternal()
                            .then((success) => {
                                $input._onValidationSuccess(ATTR.VALID._EXTERNAL, success)
                                DEBUG && console.log('processExternal then: ' + success)
                            })
                            .catch((error) => {
                                $input._onValidationError(ATTR.VALID._EXTERNAL, error)
                                DEBUG && console.log('processExternal catch: ' + error)
                            })
                    })
                    .catch((error) => {
                        $input._onValidationError(ATTR.VALID._CUSTOM, error)
                        DEBUG && console.log('processCustom catch: ' + error)
                    })
            })
            .catch((error) => {
                $input._onValidationError(ATTR.VALID._CLIENT, error)
                DEBUG && console.log('processClient catch: ' + error)
            })
            .finally(() => {
                $input._addFeedback()
                $input._notify()
            })
    }

    $input.on(errorEvents, async (e) => {
        DEBUG && console.log(e)
        $input._isInValid() && (await $input._validate())
    })

    $input.on(events, async (e) => {
        DEBUG && console.log(e)
        await $input._validate()
    })

    !$input._isEmpty() && $input._validate()

    return $input
}

export const pair$Elements = ($submit, $input) => {
    if ($input._isDetached()) {
        return
    }

    $submit._addInput($input)
    $input._addSubmit($submit)
}
