import _ from 'lodash'
import $ from 'jquery'

import { closeWidget, initWidget, openWidget, setWidgetData } from '../autocomplete'
import { $elementHasVal, elementExists } from '../shared'
import { selectors } from '../selectors'
import { event } from '../validation/ruleset'
import { input } from '../validation/enums'
import { employerRequest, selfEmployerRequest } from '../ajaxCalls'
import { $feedbackSpinnerFinnish, hasSuccess } from '../validation/helpers'

export const EMPLOYER_CONTRACT = {
    INDEFINITE: 'indefinite period',
    FIXED: 'fixed period',
}

const FORM_DATA_ATTR_SELECTOR = '[data-reveal-employer]'
const FORM_DATA_ATTR = 'data-reveal-employer'
const ARROW_UP = 'ArrowUp'
const ARROW_DOWN = 'ArrowDown'
const ENTER = 'Enter'

export const init = () => {
    hideAll() // Just in case, elements should have [style="display: none;"] attr

    initEmployerAutocomplete(selectors.inputs.employer) // RegistrationClassic
    initEmployerAutocomplete(selectors.inputs.employerComplete) // RegistrationComplete

    initIcoAutocomplete(selectors.inputs.selfEmployerIc) // RegistrationClassic
    initIcoAutocomplete(selectors.inputs.selfEmployerIcComplete) // RegistrationComplete

    initFor(selectors.inputs.employerContract) // RegistrationClassic
    initFor(selectors.inputs.employerContractComplete) // RegistrationComplete
    initFor(selectors.inputs.employerContractClientComplete) // ClientData + ClientComplete
    initFor(selectors.inputs.employerContractClientActualize) // Client Actualize
}

// ------------------------------------------------------

const initFor = (selector) => {
    if (!elementExists(selector)) {
        return
    }

    const $choicesEmployerContract = $(selector)

    $choicesEmployerContract.on(event[input.radio], () => {
        const selectedIdent = $(selector + ':checked').val()
        revealFormSections(selectedIdent)
    })

    $elementHasVal($choicesEmployerContract) && $(selector + ':checked').trigger(event[input.radio])
}

const initAutocomplete = (selector, requestFunction) => {
    if (!elementExists(selector)) {
        return
    }

    $(selector).on('keyup', (event) => {
        const keyPressed = event.key
        if (keyPressed !== ARROW_DOWN && keyPressed !== ARROW_UP && keyPressed !== ENTER) {
            hasSuccess($(selector)) && requestFunction($(selector))
        }
    })
}

const initEmployerAutocomplete = (selector) => {
    initAutocomplete(selector, employerRequest)
}

const initIcoAutocomplete = (selector) => {
    initAutocomplete(selector, selfEmployerRequest)
}

const hideAll = () => $(FORM_DATA_ATTR_SELECTOR).hide()

const revealFormSections = (employerIdent) => {
    $(FORM_DATA_ATTR_SELECTOR).each((_, element) => {
        $(element).attr(FORM_DATA_ATTR).includes(employerIdent) ? $(element).show() : $(element).hide() // slide[Down|Up] -> [show|hide] : CC-2837
    })
}

// ------------------------------------------------------

/**
 * @param data[] => {ico: str, name: str}
 */
export const handleDataResponseEmployer = (data) => {
    const isRegistrationForm = elementExists(selectors.forms.registrationForm)
    const $input = $(isRegistrationForm ? selectors.inputs.employer : selectors.inputs.employerComplete)
    const $inputIco = $(isRegistrationForm ? selectors.inputs.employerIc : selectors.inputs.employerIcComplete)
    const noResults = data.length === 0
    const multipleHits = data.length >= 1

    $input.autocomplete('instance') && $input.autocomplete('destroy')

    initWidget($input, data)
    setWidgetData(
        $input,
        _.map(data, (row) => {
            return {
                label: row.name + ' ( ' + row.city + ' ) ( ' + row.ico + ' )',
                value: row.name,
            }
        })
    )

    $inputIco.val('')
    noResults && closeWidget($input)
    multipleHits && openWidget($input)
    $feedbackSpinnerFinnish($input)
}

/**
 * @param data[] => { ... }
 * @param options => { reject: fn(), resolve: fn() }
 */
export const handleDataResponseSelfEmployer = (data) => {
    const isRegistrationForm = elementExists(selectors.forms.registrationForm)
    const $input = $(isRegistrationForm ? selectors.inputs.selfEmployerIc : selectors.inputs.selfEmployerIcComplete)
    const $inputSphere = $(isRegistrationForm ? selectors.inputs.selfEmployerSphere : selectors.inputs.selfEmployerSphereComplete)
    const $inputPlace = $(isRegistrationForm ? selectors.inputs.selfEmployerPlace : selectors.inputs.selfEmployerPlaceComplete)
    const noResults = data.length === 0
    const multipleHits = data.length >= 1

    $input.autocomplete('instance') && $input.autocomplete('destroy')

    initWidget($input, data)
    setWidgetData(
        $input,
        _.map(data, (row) => {
            return {
                label: row.ico + ' ( ' + row.name + ' ) ( ' + row.city + ' )',
                value: row.ico,
            }
        })
    )

    $inputSphere.val('')
    $inputPlace.val('')

    noResults && closeWidget($input)
    multipleHits && openWidget($input)
    $feedbackSpinnerFinnish($input)
}

export const setEmployerIcValue = (data, value) => {
    const isRegistrationForm = elementExists(selectors.forms.registrationForm)
    const $inputIco = $(isRegistrationForm ? selectors.inputs.employerIc : selectors.inputs.employerIcComplete)

    const legalEntitySelected = data.find((legalEntity) => {
        return legalEntity.name === value
    })

    legalEntitySelected && $inputIco.val(legalEntitySelected.ico)
}

export const setSphereAndPlace = (data, value) => {
    const isRegistrationForm = elementExists(selectors.forms.registrationForm)
    const $inputSphere = $(isRegistrationForm ? selectors.inputs.selfEmployerSphere : selectors.inputs.selfEmployerSphereComplete)
    const $inputPlace = $(isRegistrationForm ? selectors.inputs.selfEmployerPlace : selectors.inputs.selfEmployerPlaceComplete)

    const legalEntitySelected = data.find((legalEntity) => {
        return legalEntity.ico === value
    })

    legalEntitySelected && $inputSphere.val(legalEntitySelected.sphere)
    legalEntitySelected && $inputPlace.val(legalEntitySelected.city)
}

export const completeValues = (event, data, value) => {
    const $input = $(event.target)
    const completeIc = $input.is($(selectors.inputs.employer)) || $input.is($(selectors.inputs.employerComplete))
    const completeSphereAndPlace = $input.is($(selectors.inputs.selfEmployerIc)) || $input.is($(selectors.inputs.selfEmployerIcComplete))

    completeIc && setEmployerIcValue(data, value)
    completeSphereAndPlace && setSphereAndPlace(data, value)
}
